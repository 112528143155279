define(function(require) {
    const AudienceCardTemplate = require('./AudienceCard.template.html');
    const CardView = require('./MetricCard.view');
    const _ = require('underscore');
    const CC = require('CC');
    const { authorize } = require('utils/authorization');
    require('./AudienceCard.css');

    const AudienceCardView = CardView.extend({
        template: _.template(AudienceCardTemplate),

        defaults: function() {
            return {
                clientId: CC.utils.getCurrentClientId(),
                isNetwork: false,
                cardStyle: 'terrene',
                maxProfileAudiences: undefined,
                viewBenchmarkAudiences: authorize('ViewProfileAudiences') || authorize('ManageProfileAudiences')
            };
        },

        events: {},

        validation: [
            {
                name: 'clientActivity',
                required: true
            },
            {
                name: 'entityType',
                required: false
            }
        ],

        initialize: function(options) {
            this.isFirstRender = false;

            options.title = 'Audiences';
            options.headerLinks = [
                {
                    name: 'Create New Audience',
                    href: 'audience/lab'
                },
                {
                    name: 'Manage Audiences',
                    href: 'audience/find'
                }
            ];

            CardView.prototype.initialize.apply(this, arguments);
        },

        renderContent: function() {
            const self = this;

            const abbreviation = self.$('.card-body').width() > 375 ? 'standard' : 'tiny';
            const viewOptions = {
                state: self.state,
                clientTypeMessage: self.isNetwork ? 'at the network and child account level' : '',
                viewBenchmarkAudiences: this.viewBenchmarkAudiences
            };

            self.$('.card-body').html(this.template(viewOptions));
            self.$('[data-toggle="tooltip"]').tooltip();
            if (self.isFirstRender) {
                window.addEventListener(
                    'resize',
                    _.debounce(() => {
                        this.renderContent();
                    })
                );
                self.isFirstRender = false;
            }
            this.renderMetric(
                '.audience-card-current-audience-count',
                this.metric.get('currentAudienceCount'),
                abbreviation
            );

            this.renderMetric('.audience__first-party-only', this.metric.get('firstPartyDataOnlyAudienceCount'));
            this.renderMetric('.audience__contains-purchased', this.metric.get('hasPurchasedDataAudienceCount'));
            this.renderMetric('.audience__expires-soon', this.metric.get('expiringSoonAudienceCount'));
            this.renderMetric('.audience__generates-analytics', this.metric.get('generatesAnalyticsAudienceCount'));
            this.renderMetric(
                '.audience__benchmark',
                this.metric.get('benchmarkAudienceCount'),
                'tiny',
                this.maxProfileAudiences
            );

            return self;
        }
    });

    return AudienceCardView;
});
