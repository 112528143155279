const CC = require('CC');
const UserSettingUtil = require('utils/userSetting');
const Logger = require('Logger').default;

export const authorizeForPanoramaIdCountStats = () => {
    const logger = new Logger('authorizeForPanoramaIdCountStats');
    const ALPHA_SETTING = 'showTotalProfilesAudienceStats';
    const alphaSetting = UserSettingUtil.getUserSetting(ALPHA_SETTING);
    const FEATURE_NAME = 'ProfileStats';
    const clientAllowed = authorize(FEATURE_NAME, null, {
        alphaUserSetting: ALPHA_SETTING
    });

    if (!clientAllowed && !alphaSetting) {
        return false;
    }

    // Only check panoramaIds if we might allow access
    const hasPanoramaIds = checkClientFlag('cartographerEnabled');

    let isAllowed = (clientAllowed || alphaSetting) && hasPanoramaIds;

    // Handle overrides
    if (CC.utils.getUserSessionInfo('hasDataOverride')) {
        const wasAllowed = isAllowed;

        if (hasBeenOverriddenOn(FEATURE_NAME)) {
            isAllowed = true;
            if (!wasAllowed && logger.isDebugEnabled()) {
                logger.debug('ProfileStats: Forced ON via override');
            }
        }

        if (hasBeenOverriddenOff(FEATURE_NAME)) {
            isAllowed = false;
            if (wasAllowed && logger.isDebugEnabled()) {
                logger.debug('ProfileStats: Forced OFF via override');
            }
        }
    }

    // Debug logging with guard clauses
    if (logger.isDebugEnabled()) {
        if (!clientAllowed && alphaSetting) {
            logger.debug('authorizeForPanoramaIdCountStats: Enabled via alpha setting fallback');
        }
        logger.debug(`hasFeature: ${clientAllowed}, alphaSetting: ${alphaSetting}, hasPanoramaIds: ${hasPanoramaIds}`);
    }

    return isAllowed;
};

const checkClientFlag = function(flagName) {
    const flags = CC.utils.getCurrentClientFlags();
    if (!flags) return false;
    return flags.get(flagName);
};

const hasFeatureOverride = (name, storeKey) => {
    const feature = CC.DataStore.get(storeKey);
    if (!feature) return false;

    const normalizedName = name.toLowerCase();

    try {
        const decodedName = decodeURIComponent(feature);

        if (decodedName.toLowerCase() === normalizedName) return true;

        return decodedName.includes(',')
            ? decodedName.split(',').some(featureName => normalizedName === featureName.trim().toLowerCase())
            : false;
    } catch (e) {
        console.error('Error decoding feature name:', e);
        return false;
    }
};

const authorizeForFeature = (featureName, options) => {
    const logger = new Logger('authorizeForFeature');
    let isAllowed = false;
    const feature = CC.SessionData.allFeatures.findWhere({ name: featureName });
    if (feature) {
        const rolloutStatus = feature.get('rolloutStatus');

        switch (rolloutStatus) {
            case 'PREVIEW': {
                isAllowed = hasBeenOverriddenOn(featureName);
                break;
            }
            case 'ALPHA': {
                isAllowed = UserSettingUtil.getUserSetting(options.alphaUserSetting);
                break;
            }
            case 'BETA':
            case 'GA': {
                // Check client-level access
                if (options.clientId) {
                    const entityFeatures = CC.DataStore.get(`clientFeatures_${options.clientId}`);
                    if (entityFeatures) {
                        isAllowed = !!entityFeatures.findWhere({ name: featureName });
                    }
                }

                // Check current user's client if needed
                if (!isAllowed) {
                    isAllowed = !!CC.SessionData.userFeatures.findWhere({ name: featureName });
                }

                break;
            }
            case 'INTEGRATED': {
                isAllowed = true;
                break;
            }
            default:
                isAllowed = false;
        }

        // Override
        if (CC.utils.getUserSessionInfo('hasDataOverride')) {
            const wasAllowed = isAllowed;
            if (hasBeenOverriddenOn(featureName)) {
                isAllowed = true;
                if (!wasAllowed) {
                    logger.debug(`${featureName}: Forced ON via override`);
                }
            }
            if (hasBeenOverriddenOff(featureName)) {
                isAllowed = false;
                if (wasAllowed) {
                    logger.debug(`${featureName}: Forced OFF via override`);
                }
            }
        }
    }

    return isAllowed;
};

// Usage:
const hasBeenOverriddenOn = name => hasFeatureOverride(name, 'with_feature');
const hasBeenOverriddenOff = name => hasFeatureOverride(name, 'without_feature');

/**
 * Authorize access by features & permissions
 *
 * By default, checks agains the features enabled for the Current Client Id
 * If a client id is provided, use the features set for that client Id (aka what has been
 * @param {Array|String} features
 * @param {Array|String} permissions
 * @param {Object} options
 * @param {Number?} options.clientId
 * @param {String} options.alphaUserSetting
 *
 * @returns {Boolean}
 */
export const authorize = (
    features,
    permissions = [],
    options = {
        clientId: undefined,
        alphaUserSetting: 'useUpdatedUIStyle'
    }
) => {
    const logger = new Logger('authorize');
    // handling for case where no one is logged in. May remove and streamline that path
    if (typeof CC.SessionData.userInfo === 'undefined') {
        logger.debug(`${featureName}: No user info, denying access`);
        return false;
    }
    if (typeof CC.SessionData.allFeatures === 'undefined') {
        logger.debug(`${featureName}: No features, denying access`);
        return false;
    }
    if (typeof CC.SessionData.userFeatures === 'undefined') {
        logger.debug(`${featureName}: No user features, denying access`);
        return false;
    }

    let isAllowed = true;
    
    if (!_.isEmpty(features)) {
        // features parameter may be a single string or an array
        if (!_.isArray(features)) {
            features = [features];
        }
        for (let i = 0; i < features.length; i++) {
            if (!authorizeForFeature(features[i], options)) {
                isAllowed = false;
                break;
            }
        }
    }

    if (isAllowed && !_.isEmpty(permissions)) {
        // permissions parameter may be a single string or an array
        if (!_.isArray(permissions)) {
            permissions = [permissions];
        }

        for (let x = 0; x < permissions.length; x++) {
            if (!CC.authorizeByPermission(permissions[x])) {
                isAllowed = false;
                break;
            }
        }
    }

    return isAllowed;
};

/**
 * Apply the feature overrides to a url
 * @param url
 * @param baseUrl
 * @returns {string} The url with any features overrides appended
 */
export const appendOverridesToUrl = (url, baseUrl = window.location.origin) => {
    // Extract path and query parts
    const [path, existingQuery] = url.split('?');
    const params = new URLSearchParams(existingQuery || '');

    // Get feature flags
    const withFeature = CC.DataStore.get('with_feature');
    const withoutFeature = CC.DataStore.get('without_feature');

    // Set feature parameters if they exist
    if (withFeature) {
        params.set('with_feature', withFeature);
    }
    if (withoutFeature) {
        params.set('without_feature', withoutFeature);
    }

    // Build the final URL preserving the relative path
    const queryString = params.toString();
    return queryString ? `${path}?${queryString}` : path;
};
