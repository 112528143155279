define(function(require) {
    const DetailsTemplate = require('./TransactionBreakoutDetails.template.html');
    const BaseView = require('app/Base.view');
    const _ = require('underscore');
    const CC = require('CC');

    const TransactionBreakoutDetailsView = BaseView.extend({
        template: _.template(DetailsTemplate),

        initialize: function(options) {
            this.options = _.extend(this.defaults, this.options);
        },

        render: function() {
            this.$el.empty().append(this.defaultLoader);
            this.renderDetails();
            return this;
        },

        renderDetails: function() {
            const ltJsCount = this.model.get('ltJsCount');
            const syncJsCount = this.model.get('syncJsCount');
            const ingestorRecordCount = this.model.get('ingestorRecordCount');
            const otherCount = this.model.get('otherCount');
            const total =
                (ltJsCount ? ltJsCount : 0) +
                (syncJsCount ? syncJsCount : 0) +
                (ingestorRecordCount ? ingestorRecordCount : 0) +
                (otherCount ? otherCount : 0);

            const templateOptions = {
                ltJsCount: ltJsCount ? `${CC.converters.formatNamedNumber(ltJsCount)}` : 'N/A',
                syncJsCount: syncJsCount ? `${CC.converters.formatNamedNumber(syncJsCount)}` : 'N/A',
                ingestorRecordCount: ingestorRecordCount
                    ? `${CC.converters.formatNamedNumber(ingestorRecordCount)}`
                    : 'N/A',
                otherCount: otherCount ? `${CC.converters.formatNamedNumber(otherCount)}` : 'N/A',
                total: total ? `${CC.converters.formatNamedNumber(total)}` : 'N/A'
            };

            this.$el.empty().append(this.template(templateOptions));
        }
    });
    return TransactionBreakoutDetailsView;
});
