define(function(require) {
    const ClientActivityTemplate = require('./ClientActivity.template.html');
    const MetricCardView = require('../MetricCard.view');
    const TransactionCardView = require('../TransactionCard.view');
    const AudienceCardView = require('../AudienceCard.view');
    const BehaviorCardView = require('../BehaviorCard.view');
    const ClientActivityModel = require('models/reports/ClientActivity.model');
    const DataInView = require('../DataIn.view');
    const EmailClientStatsView = require('../EmailClientStats.view');
    const DataOutView = require('../DataOut.view');
    const TrendChartView = require('../TrendChart.view');
    const BaseView = require('dcViews/Base.view');
    const _ = require('underscore');
    const Backbone = require('backbone');
    const moment = require('moment');
    const CC = require('CC');
    const dc = require('dc');
    const { authorizeForPanoramaIdCountStats } = require('utils/authorization');
    const { authorize } = require('utils/authorization');
    require('./ClientActivity.css');
    require('components/Button.css');

    const ClientActivityView = BaseView.extend({
        template: _.template(ClientActivityTemplate),

        defaults: function() {
            return {
                client: undefined,
                emailCapability: undefined,
                audiences: undefined,
                lastRunModel: undefined,
                showAudienceCards: authorize('Audiences', 'ViewAudienceStats'),
                showTransactionCards: authorize('Behaviors', 'View Behaviors'),
                showClientAndServerSideCards: authorizeForPanoramaIdCountStats(),
                showBehaviorCards: authorize(['Behaviors', 'FirstPartyData'], 'View Behaviors'),
                showCampaignCards: authorize('Campaigns', 'View Campaigns'),
                showExtractionApi: false,
                showEmailClientStats: false,
                showBCPCalls: false,
                dataDate: undefined
            };
        },

        events: {},

        validation: [
            {
                name: 'allMembersStatsCollection',
                required: true
            },
            {
                name: 'client',
                required: true
            }
        ],

        initialize: function(options) {
            const self = this;

            _.extend(self, self.defaults(), options);

            self.clientActivity = new Backbone.Model({
                state: 'initial'
            });

            self.showExtractionApi = self.client.get('extractionApiEnabled');
            self.showBCPCalls = self.client.get('bcpCallsEnabled');
            this.showEmailClientStats = this.emailCapability.isEnabled();

            self.listenTo(self.clientActivity, 'change:state', function(model, newState) {
                switch (newState) {
                    case 'loading':
                        self.fetchMetrics();
                        break;
                    case 'loaded':
                        self.clearMainLoaderTimer();
                        self.renderContent();
                        self.renderMetrics();
                        break;
                }
            });

            self.validateProperties();

            const dataDate = this.dataDate ? moment(this.dataDate, 'YYYYMMDD') : moment(self.lastRunModel.getDateObj());
            self.monthlyBillingModel = new ClientActivityModel(null, {
                params: {
                    clientId: self.client.get('id')
                }
            });
            if (this.dataDate) {
                this.monthlyBillingModel.setParam('data_date', this.dataDate);
            }

            self.model = new Backbone.Model({
                dateRange: dataDate,
                tagUniques: 0,
                audienceCount: 0,
                totalBehaviorCount: 0,
                totalCampaignCount: 0,
                activationChannelCount: 0,
                ingestorRecordCount: 0,
                apiRequestCount: 0,
                bcpCount: 0,
                totalUniques: 0,
                totalTransactions: new Backbone.Collection()
            });

            BaseView.prototype.initialize.apply(this, arguments);
        },

        fetchMetrics: function() {
            const self = this;

            const collections = [self.monthlyBillingModel];

            self.fetchAndWait(collections, function(xhr, xhrState, data, errors) {
                self.model.set('error', _.findWhere(errors, { i: 0 }));

                self.model.set(self.monthlyBillingModel.attributes);

                self.model.set('tagUniques', self.allMembersStatsCollection.totalUniques);
                self.clientActivity.set('state', 'loaded');
            });
        },

        render: function() {
            const dataDate = this.dataDate
                ? moment(this.dataDate, 'YYYYMMDD').format('MMMM DD, YYYY')
                : this.lastRunModel.getTime();
            this.model.set('statpumpDate', dataDate);
            this.clientActivity.set('state', 'loading');
            this.renderMainLoader();

            return this;
        },

        renderContent: function() {
            const self = this;

            const viewOptions = {
                dateRange: self.model.get('dateRange').format('MMMM D, YYYY'),
                showAudienceCards: self.showAudienceCards,
                showBehaviorCards: self.showBehaviorCards,
                showTransactionCards: self.showTransactionCards,
                showClientAndServerSideCards: self.showClientAndServerSideCards,
                showCampaignCards: self.showCampaignCards,
                statpumpDate: self.model.get('statpumpDate'),
                showEmailClientStats: this.showEmailClientStats,
                showExtractionApi: this.showExtractionApi
            };
            self.$el.html(this.template(viewOptions));
        },

        renderMetrics: function() {
            const self = this;
            const showTransactionSourceBreakout = authorize('TransactionSourceBreakout');

            if (self.showClientAndServerSideCards) {
                // new cards for client-side and server-side uniques
                self.createAndRenderSubView('.client-activity-server-side-uniques', MetricCardView, {
                    metric: [
                        {
                            value: self.model.get('clientMtdStats')?.serverSideUniques ?? 0,
                            label: 'Server Side Unique IDs',
                            tooltip: 'A de-duplicated count of server side profiles',
                            showGraph: true
                        }
                    ],
                    entityType: 'profiles',
                    state: self.model.get('error') ? 'error' : self.clientActivity.get('state')
                });
                self.createAndRenderSubView(
                    '.metric-card-container[data-metric="server-side-unique-ids"] .metric-card-graph',
                    TrendChartView,
                    {
                        data: new Backbone.Collection(self.model.get('clientDailyStats')).models,
                        name: 'server-side-uniques',
                        field: 'serverSideUniques',
                        state: self.clientActivity.get('state'),
                        groupName: 'Uniques'
                    }
                );
                self.createAndRenderSubView('.client-activity-client-side-uniques', MetricCardView, {
                    metric: [
                        {
                            value: self.model.get('clientMtdStats')?.clientSideUniques ?? 0,
                            label: 'Client Side Unique IDs',
                            showGraph: true,
                            tooltip: 'A de-duplicated count of client side profiles'
                        }
                    ],
                    entityType: 'profiles',
                    state: self.model.get('error') ? 'error' : self.clientActivity.get('state')
                });
                self.createAndRenderSubView(
                    '.metric-card-container[data-metric="client-side-unique-ids"] .metric-card-graph',
                    TrendChartView,
                    {
                        name: 'client-side-uniques',
                        data: new Backbone.Collection(self.model.get('clientDailyStats')).models,
                        field: 'clientSideUniques',
                        state: self.clientActivity.get('state'),
                        groupName: 'Uniques'
                    }
                );
            } else {
                // old (deprecated) cards for active uniques
                self.createAndRenderSubView('.client-activity-server-side-uniques', MetricCardView, {
                    metric: [
                        {
                            value: self.model.get('totalUniques') == 0 ? null : self.model.get('totalUniques'),
                            label: 'Total Active Uniques',
                            tooltip: 'A de-duplicated count of all tag-based and server side profiles',
                            showGraph: true
                        }
                    ],
                    entityType: 'profiles',
                    state: self.model.get('error') ? 'error' : self.clientActivity.get('state')
                });
                self.createAndRenderSubView(
                    '.metric-card-container[data-metric="total-active-uniques"] .metric-card-graph',
                    TrendChartView,
                    {
                        data: new Backbone.Collection(self.model.get('clientUniques')).models,
                        name: 'total-uniques',
                        field: 'dailyUniques',
                        state: self.clientActivity.get('state'),
                        groupName: 'Uniques'
                    }
                );
                self.createAndRenderSubView('.client-activity-client-side-uniques', MetricCardView, {
                    metric: [
                        {
                            value: self.model.get('tagUniques'),
                            label: 'Client Side Active Uniques',
                            tooltip: 'A de-duplicated count of all tag-based profiles (same as All Members audience)',
                            showGraph: true
                        }
                    ],
                    entityType: 'profiles',
                    state: self.model.get('error') ? 'error' : self.clientActivity.get('state')
                });
                self.createAndRenderSubView(
                    '.metric-card-container[data-metric="client-side-active-uniques"] .metric-card-graph',
                    TrendChartView,
                    {
                        name: 'tag-uniques',
                        data: self.allMembersStatsCollection.models,
                        field: 'uniques',
                        state: self.clientActivity.get('state'),
                        groupName: 'Uniques'
                    }
                );
            }

            if (self.showTransactionCards) {
                const transactions = _.compact(
                    showTransactionSourceBreakout
                        ? [
                              self.model.get('ingestorRecordCount'),
                              self.model.get('ltJsCount'),
                              self.model.get('syncJsCount'),
                              self.model.get('otherCount')
                          ]
                        : [
                              self.model.get('extractionApiRequestCount'),
                              self.model.get('ingestorRecordCount'),
                              self.model.get('bcpCount')
                          ]
                );
                const sum = _.reduce(
                    transactions,
                    function(memo, num) {
                        return memo + num;
                    },
                    0
                );

                self.createAndRenderSubView(
                    '.client-activity-transactions',
                    showTransactionSourceBreakout ? TransactionCardView : MetricCardView,
                    {
                        metric: {
                            value: self.hasValue(transactions) ? sum : undefined,
                            label: 'Total Transactions',
                            showGraph: true,
                            tooltip: showTransactionSourceBreakout
                                ? 'The sum of LT.JS, Sync.JS, Server Side Records and Other Transactions'
                                : 'The sum of Tag Events, Server Side Records and Audience Extraction Calls'
                        },
                        transactions: self.model,
                        entityType: 'profiles',

                        state: self.model.get('error') ? 'error' : self.clientActivity.get('state')
                    }
                );
            }

            self.renderDataIn();

            if (self.showExtractionApi) {
                self.renderDataOut();
            }

            if (self.showEmailClientStats) {
                self.renderEmailClientStats();
            }

            if (self.showBehaviorCards) {
                self.createAndRenderSubView('.client-activity-behaviors', BehaviorCardView, {
                    title: 'Behaviors',
                    clientActivity: self.clientActivity,
                    metric: self.model,
                    state: self.model.get('error') ? 'error' : self.clientActivity.get('state')
                });
            }

            if (self.showAudienceCards) {
                self.createAndRenderSubView('.client-activity-audiences', AudienceCardView, {
                    metric: self.model,
                    clientActivity: self.clientActivity,
                    state: self.model.get('error') ? 'error' : self.clientActivity.get('state'),
                    isNetwork: self.client.isNetwork(),
                    maxProfileAudiences: CC.SessionData.clientFlags.get('maxProfileAudiences')
                });
            }

            if (self.showCampaignCards || self.showAudienceCards || this.showTransactionCards) {
                const metrics = [];
                const headerLinks = [];
                if (this.showAudienceCards) {
                    metrics.push({
                        value: self.model.get('audienceCount'),
                        threshold: self.model.get('maxAudienceCount'),
                        label: 'Billable Audiences',
                        showGraph: false,
                        tooltip: 'Maximum number of active billable audiences during the current billing period. ',
                        excessThresholdSuggestion:
                            'To avoid future overages, you can Delete unused audiences (individually or in bulk) or reach out to your CSM/Support to purchase additional audience bundles.'
                    });
                    headerLinks.push({
                        name: 'Manage Audiences',
                        href: 'audience/find'
                    });
                }
                if (self.showTransactionCards) {
                    const transactions = _.compact(
                        showTransactionSourceBreakout
                            ? [
                                  self.model.get('ingestorRecordCount'),
                                  self.model.get('ltJsCount'),
                                  self.model.get('syncJsCount'),
                                  self.model.get('otherCount')
                              ]
                            : [
                                  self.model.get('extractionApiRequestCount'),
                                  self.model.get('ingestorRecordCount'),
                                  self.model.get('bcpCount')
                              ]
                    );
                    const sum = _.reduce(
                        transactions,
                        function(memo, num) {
                            return memo + num;
                        },
                        0
                    );
                    metrics.push({
                        value: self.hasValue(transactions) ? sum : undefined,
                        threshold: self.model.get('maxTransactionCount'),
                        label: 'Total Transactions',
                        showGraph: false,
                        tooltip: showTransactionSourceBreakout
                            ? 'The sum of LT.JS, Sync.JS, Server Side Records and Other Transactions'
                            : 'The sum of Tag Events, Server Side Records and Audience Extraction Calls'
                    });
                }
                if (this.showCampaignCards) {
                    metrics.push({
                        value: self.model.get('totalCampaignCount'),
                        label: 'Insights Campaigns'
                    });
                    headerLinks.push({
                        name: 'Manage Campaigns',
                        href: 'insights/campaigns'
                    });
                }

                self.createAndRenderSubView('.client-activity-billing', MetricCardView, {
                    clientActivity: self.clientActivity,
                    metric: metrics,
                    entityType: 'billing',
                    format: 'compact',
                    title: 'Billing',
                    cardStyle: 'terrene',
                    state: self.model.get('error') ? 'error' : self.clientActivity.get('state'),
                    headerLinks: headerLinks
                });
            }

            self.createAndRenderSubView(
                '.metric-card-container[data-metric="total-transactions"] .metric-card-graph',
                TrendChartView,
                {
                    data: self.model.get('totalTransactions').models,
                    name: 'total-transactions',
                    field: 'total',
                    state: self.clientActivity.get('state'),
                    groupName: 'Transactions'
                }
            );

            dc.renderAll();
            this.$('[data-toggle="tooltip"]').tooltip();

            return self;
        },

        renderDataIn: function() {
            const self = this;
            self.createAndRenderSubView('.client-activity-data-in', DataInView, {
                model: self.model,
                state: this.clientActivity.get('state'),
                month: moment(this.lastRunModel.getDateObj()).format('MMMM')
            });
        },

        renderDataOut: function() {
            this.createAndRenderSubView('.client-activity-data-out', DataOutView, {
                model: this.model,
                state: this.clientActivity.get('state'),
                month: moment(this.lastRunModel.getDateObj()).format('MMMM'),
                height: 200
            });
        },

        renderEmailClientStats: function() {
            this.createAndRenderSubView('.client-activity-email-client-stats', EmailClientStatsView, {
                model: this.model,
                state: this.clientActivity.get('state'),
                month: moment(this.lastRunModel.getDateObj()).format('MMMM'),
                lastDataDate: this.model.get('dateRange')
            });
        },

        hasNoData: function(collection) {
            const values = collection.reduce(function(memo, num) {
                // Make sure value is actually a number
                return memo + +num.get('value');
            }, 0);
            return values === 0;
        }
    });

    return ClientActivityView;
});
