const CC = require('CC');
const _ = require('underscore');
const BaseCollection = require('models/Base.collection');

export default class AudienceStatsCollection extends BaseCollection {
    initialize(options) {
        const self = this;

        BaseCollection.prototype.initialize.apply(self, arguments);
        self.exportUrl = '';
    }

    urlRoot() {
        return `${CC.apiUrl}/statistics/audiences/{audienceId}`;
    }

    updateExportUrl(clientId, clientAsGroup, timeInterval, refDate, customDates) {
        const self = this;
        customDates = customDates || false;
        self.exportUrl = `${CC.apiUrl}/files/export/statistics/audiences/${self.audienceId}?client_id=${clientId}&client_as_group=${clientAsGroup}&date_range=${timeInterval}&ref_date=${refDate}&device_graph=true`;
        if (customDates && timeInterval === 'CUSTOM') {
            self.exportUrl += `&start_date=${customDates.start}`;
            self.exportUrl += `&end_date=${customDates.end}`;
        }
        this.setTimeInterval(timeInterval);
    }

    getTimeInterval() {
        return this.timeInterval;
    }

    setTimeInterval(timeInterval) {
        this.timeInterval = timeInterval;
    }

    // This will parse the response and create a collection of audience stats objects
    //    with metadata that includes the totals
    parse(response, options) {
        const self = this;

        // Pull out the array of stat objects and make the metadata collection member variables
        let mainAudienceStatsReportObject;
        let audienceStatsCollection;

        if (
            typeof response !== 'undefined' &&
            typeof response.audienceStatsReports !== 'undefined' &&
            _.isArray(response.audienceStatsReports) &&
            response.audienceStatsReports.length > 0
        ) {
            mainAudienceStatsReportObject = response.audienceStatsReports[0];

            // Add the metadata as member variables of the collection
            self.audienceName = mainAudienceStatsReportObject.audienceName;
            self.referenceDate = mainAudienceStatsReportObject.referenceDataDate;
            self.startDataDate = mainAudienceStatsReportObject.startDataDate;
            self.endDataDate = mainAudienceStatsReportObject.endDataDate;
            self.totalPageViews = mainAudienceStatsReportObject.pageViews;
            self.totalUniques = mainAudienceStatsReportObject.deviceGraphTotalUniques;

            self.customerIdStats = undefined; // TODO: future
            self.profileStats = _.isArray(mainAudienceStatsReportObject.profileStats)
                ? mainAudienceStatsReportObject.profileStats.filter(profileStat => profileStat.identifierNamespaceName)
                : [mainAudienceStatsReportObject.profileStats];
            self.panoStats = mainAudienceStatsReportObject.panoStats;

            // If results were returned, parse those and return that as the collection itself
            if (
                typeof mainAudienceStatsReportObject.audienceStats !== 'undefined' &&
                _.isArray(mainAudienceStatsReportObject.audienceStats) &&
                mainAudienceStatsReportObject.audienceStats.length > 0
            ) {
                audienceStatsCollection = mainAudienceStatsReportObject.audienceStats;
            }
        } else {
            // Clear out the metadata because nothing was returned
            self.audienceName = '';
            self.referenceDate = '';
            self.startDataDate = '';
            self.endDataDate = '';
            self.totalPageViews = '';
            self.totalUniques = '';
            self.profileStats = [];
        }

        return audienceStatsCollection || [];
    }

    isLoading() {
        return this.loading;
    }

    isLoaded() {
        return this.loaded;
    }

    getExportUrl() {
        return this.exportUrl;
    }

    getTotalCustomerIdCount() {
        if (!CC.utils.hasValue(this.customerIdStats)) {
            return 0;
        }
        const total = this.customerIdStats.reduce((previousValue, currentValue, data) => {
            return previousValue + currentValue.profiles;
        }, 0);
        return total;
    }

    getMostRecentPanoramaIdCount() {
        if (!CC.utils.hasValue(this.panoStats) || !CC.utils.hasValue(this.panoStats[0]) || !CC.utils.hasValue(this.panoStats[0].dataDate)) {
            return 0;
        }
        // find most recent date in the list
        const maxDate = this.panoStats.reduce((max, stat) => {
            return stat.dataDate > max ? stat.dataDate : max;
        }, this.panoStats[0].dataDate);
        // retrieve the sum of all values for that date - 
        // in practice there really should only be 1 value for that date
        // but this allows that there could be more
        const total = this.panoStats.reduce((previousValue, currentValue, data) => {
            if (currentValue.dataDate == maxDate) {
                return previousValue + currentValue.uniques;
            }
            return previousValue;
        }, 0);
        return total;
    }

    getTotalEmailsCount() {
        if (!CC.utils.hasValue(this.profileStats)) {
            return 0;
        }
        const total = this.profileStats.reduce((previousValue, currentValue, data) => {
            if (currentValue.identifierNamespaceName == 'Emails') {
                return previousValue + currentValue.profiles;
            }
            return previousValue;
        }, 0);
        return total;
    }

    getTotalPageViewCount() {
        if (!CC.utils.hasValue(this.profileStats)) {
            return 0;
        }
        const total = this.profileStats.reduce((previousValue, currentValue, data) => {
            return previousValue + currentValue.pageViews;
        }, 0);
        return total;
    }

    getProfileCountGroupedByScheme() {
        const results = [];
        if (CC.utils.hasValue(this.profileStats)) {
            this.profileStats.reduce((previousValue, currentValue) => {
                if (!previousValue[currentValue.identifierNamespaceName]) {
                    previousValue[currentValue.identifierNamespaceName] = {
                        id: currentValue.identifierNamespaceId,
                        name: currentValue.identifierNamespaceName,
                        profiles: 0,
                        pageViews: 0
                    };
                    results.push(previousValue[currentValue.identifierNamespaceName]);
                }
                previousValue[currentValue.identifierNamespaceName].profiles += currentValue.profiles;
                previousValue[currentValue.identifierNamespaceName].pageViews += currentValue.pageViews;
                return previousValue;
            }, {});
        }
        return results;
    }

    getDailyPanoramaIdCounts() {
        const results = [];
        if (CC.utils.hasValue(this.panoStats)) {
            this.panoStats.reduce((previousValue, currentValue) => {
                if (!previousValue[currentValue.dataDate]) {
                    previousValue[currentValue.dataDate] = {
                        dataDate: currentValue.dataDate,
                        panoramaIds: 0
                    };
                    results.push(previousValue[currentValue.dataDate]);
                }
                previousValue[currentValue.dataDate].panoramaIds += currentValue.uniques;
                return previousValue;
            }, {});
        }
        return results;
    }
}
